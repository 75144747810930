import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import Seo from "../../components/Seo/Seo";
import Umbrella from "../../components/Umbrella/Umbrella";
import {
  ArrowRight24Icon,
  MessageIcon,
} from "../../icons";
import WrappedAniLink from "../../utils/WrappedAniLink";
import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";

const IndexPage = ({ data: { page } }) => {
  const { language } = useI18next();
  const langPath = language.substring(0, 2);

  return (
    <MainLayout
      header={(
        <Header
          headline={page.headline}
          text={<BlockContent blocks={page._rawDescription} />}
          cta={(
            <Button
              size="small"
              title="Work with us"
              color="white"
              variant="primary"
              href="/contact"
            >
              Work with us <MessageIcon />
            </Button>
          )}
          variant="blank"
          theme="dark"
          bgImageUrl="url(/images/backgrounds/start.webp)"
          bgVideo="home"
          umbrella={(
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-30 gap-y-15 w-full">
              <WrappedAniLink to={`/${langPath}/what-we-do`} title="what_we_do">
                <Umbrella animate="hover" label="What we do" color="white" icons={<ArrowRight24Icon />} />
              </WrappedAniLink>
              <WrappedAniLink to={`/${langPath}/what-we-did`} title="what_we_did">
                <Umbrella animate="hover" label="What we did" color="white" icons={<ArrowRight24Icon />} />
              </WrappedAniLink>
              <WrappedAniLink to={`/${langPath}/who-we-are`} title="who_we_are">
                <Umbrella animate="hover" label="Who we are" color="white" icons={<ArrowRight24Icon />} />
              </WrappedAniLink>
            </div>
          )}
        />
      )}
    >
      <AllComponents elements={page.contentElements} />
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) { 
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityHomepage(id: {eq: $id}) {
      i18n_lang
      id
      title
      headline
      _rawDescription
      slug {
        current
      }
      contentElements {
        ... on SanitySectionHeader {
          _key
          _type
          descriptionText
          headline
          color
          sectionLabel
          showCTA
          sectionID
          button {
            variant
            size
            color
            title
            icon
            internalLink {
              ... on SanityServiceDetail {
                id
                slug {
                  current
                }
              }
              ... on SanityServiceOverview {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionDetail {
                id
                slug {
                  current
                }
              }
              ... on SanitySolutionOverview {
                id
                slug {
                  current
                }
              }
              ... on SanityLegalDocument {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                variant
              }
            }
          }
        }
        ... on SanityServiceCardList {
          _key
          _type
          serviceCardList {
            background
            _rawDescription
            label
            variant
            button {
              variant
              size
              color
              title
              icon
              internalLink {
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityLegalDocument {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  variant
                }
              }
            }
            umbrellaContent {
              label
              tags {
                tags {
                  url
                  label
                }
                useJumpmarks
              }
            }
          }
        }
        ... on SanitySolutionCardList {
          _key
          _type
          showAs
          cardSize
          imagePosition
          solutionCardList {
            headline
            label
            background
            showButton
            image {
              ...ImageWithPreview
            }
            logo {
              ...ImageWithPreview
            }
            services {
              name
              variant
              lead {
                image {
                  ...ImageWithPreview
                }
                mail
                name
                telephone
                id
              }
            }
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanitySolutionDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
          }
        }
        ... on SanityOfficeCardList {
          _key
          _type
          officeCardList {
            areaTitle
            headline
            _rawAddress
            icon {
              ...ImageWithPreview
            }
            services {
              name
              variant
              lead {
                image {
                  _key
                  _type
                  _rawAsset
                  _rawHotspot
                  _rawCrop
                }
                mail
                name
                telephone
                id
              }
            }
          }
        }
        ... on SanityContentCardList {
          _key
          _type
          variant
          initiallyOpen
          actAsAccordion
          sectionID
          contentCardList {
            headline
            variant
            _rawDescription
            icon
            showRelatedLinks
            showPrimaryCTA
            primaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            showSecondaryCTA
            secondaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            relatedLinks {
              ... on SanityLegalDocument {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityService {
                _type
                id
                name
                variant
              }
            }
            overline {
              label
              variant
            }
            _rawDescription
          }
        }
        ... on SanityCTACard {
          _key
          _type
          sectionID
          variant
          image {
            asset {
              url
            }
          }
          label
          headline
          icon
          _rawDescription
        }
        ... on SanityScrollVideoCard {
          _key
          _type
          sectionID
          URL
        }
        ... on SanityContentSection {
          _key
          _type 
          color
          contentSections {
            _type
            headline
            variant
            overline {
              label
              variant
            }
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            _rawDescription
            label
            image {
              ...ImageWithPreview
            }
            overlineIcon {
              ...ImageWithPreview
            }
            position
            relatedLinks {
              ... on SanityService {
                id
                name
                _type
              }
              ... on SanityHomepage {
                id
                slug {
                  current
                }
                title
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityLegalDocument {
                id
                title
                slug {
                  current
                }
              }
            }
            umbrellaLabel
            variant
          }
        }
        ... on SanityContactSection {
          _key
          _type 
          title
          color
        }  
        ... on SanityContentModuleEmbed {
          _key
          _type
          headline
          icon
          color
          label
          position
          image {
            ...ImageWithPreview
          }
          button {
            variant
            size
            color
            title
            internalLink {
              ... on SanityLegalDocument {
                _type
                id
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                _type
              }
            }
          }
          relatedLinks {
            ... on SanityService {
              id
              name
              _type
            }
            ... on SanityHomepage {
              id
              slug {
                current
              }
              title
            }
            ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
            ... on SanityLegalDocument {
              id
              title
              slug {
                current
              }
            }
          }
          _rawDescription
        }
      }
    }
 }
`;

IndexPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default IndexPage;
